import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AccountContext } from "../../contexts/AccountContext";
import { Link, useNavigate } from "react-router-dom";
import Satis from "../pages/Satis";
import Kvkk from "../pages/Kvkk";
import cancel from "../../utils/img/icons/remove.png";
import info from "../../utils/img/icons/info-pale.svg";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import InputMask from "react-input-mask";
import "react-datetime/css/react-datetime.css";
import SocialAuth from "./components/SocialAuth";
import AcikRiza from "./components/AcikRiza";
import email from "../../utils/img/mail-svg.svg";
import password from "../../utils/img/password-svg.svg";
import person from "../../utils/img/person-svg.svg";
import call from "../../utils/img/call.svg";

import Aydinlatma from "./components/Aydinlatma";
import { Helmet } from "react-helmet-async";
import { GoogleOAuthProvider } from "@react-oauth/google";
import baseService from "../../library/network/services/BaseService";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";
import { useDispatch } from "react-redux";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import { registerSuccess } from "../../store/slices/accountSlice";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const recaptchaRef = React.useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [aydınlatma, setAydinlatma] = useState(false);
  const [iletisimIzni, setIletisimIzni] = useState(false);
  const [onayMetni, setOnayMetni] = useState(false);
  const [satisSozlesmesi, setSatisSozlesmesi] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const showPopupHandler = () => {
    setShowPopup(true);
  };
  const tokenService = () =>
    baseService.post("verify-token", { token: captchaToken });

  const PHONE_REGEX = /^05(\d){2} ?\d{3} ?\d{4}$/;

  const hidePopupHandler = () => {
    setShowPopup(false);
  };

  const { register } = useContext(AccountContext);
  return (
    <>
      <Helmet>
        <title>{`Üye Ol - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>{" "}
      {onayMetni && <AcikRiza setOnayMetni={setOnayMetni} />}
      {aydınlatma && (
        <div className="modal-overlay">
          <div className="terms-container">
            {" "}
            <Kvkk />
            <button
              onClick={() => setAydinlatma(false)}
              className="btn cancel-button"
            >
              Kapat
            </button>
          </div>
        </div>
      )}
      {iletisimIzni && <Aydinlatma setAydinlatma={setIletisimIzni} />}
      {satisSozlesmesi && (
        <div className="modal-overlay">
          <div className="terms-container">
            {" "}
            <Satis />
            <button
              onClick={() => setSatisSozlesmesi(false)}
              className="btn cancel-button"
            >
              Kapat
            </button>
          </div>
        </div>
      )}
      <div className="register-content mt-5 mx-4">
        <h1>Üye Ol</h1>
        <p className="web">
          Hesabın var mı? <Link to="/auth/giris-yap">Giriş yap </Link>
        </p>
        <div className="web">
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
            <SocialAuth text={"Üye ol"} />
          </GoogleOAuthProvider>
        </div>
        <p className="mobile welcome-text">Online Lostra'ya hoş geldin! </p>

        <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            password: "",
            // passwordCheck: "",
            birthday: "",
            day: "",
            term: false,
            allowance: false,
            month: "",
            year: "",
          }}
          validate={(values) => {
            const errors = {};
            const trimmedFirstname = values.firstname.trim();
            const trimmedLastname = values.lastname.trim();

            if (!trimmedFirstname) {
              errors.firstname = "Adınızı giriniz.";
            } else if (trimmedFirstname.length < 2) {
              errors.firstname = "Adınız en az 2 karakter olmalıdır.";
            } else if (trimmedFirstname.length > 50) {
              errors.firstname = "Adınız en fazla 50 karakter olmalıdır.";
            }

            if (!trimmedLastname) {
              errors.lastname = "Soyadınızı giriniz.";
            } else if (trimmedLastname.length < 2) {
              errors.lastname = "Soyadınız en az 2 karakter olmalıdır.";
            }
            if (!values.day || !values.month || !values.year) {
              errors.birthday = "Doğum tarihinizi giriniz.";
            }

            if (!values.lastname) {
              errors.lastname = "Soyadınızı giriniz.";
            }
            if (!values.phone) {
              errors.phone = "Telefon Numaranızı giriniz.";
            }

            const { day, month, year } = values;
            if (day && month && year) {
              const isValidDate =
                new Date(year, month - 1, day).getDate() === parseInt(day);
              if (!isValidDate) {
                errors.day = "Geçersiz tarih";
                errors.month = "Geçersiz tarih";
                errors.year = "Geçersiz tarih";
              }
            }

            if (!values.email) {
              errors.email = "E-posta adresinizi giriniz.";
            } else if (!/\S+@\S+\.\S+/.test(values.email)) {
              errors.email = "Geçersiz e-posta adresi.";
            }
            if (!values.password) {
              errors.password = "Şifrenizi giriniz.";
            } else if (values.password.length < 6) {
              errors.password = "Şifreniz en az 6 karakter olmalıdır.";
            }
            if (!values.term) {
              errors.term = "Lütfen sözleşmeyi kabul ediniz.";
            }
            if (!values.phone) {
              errors.phone = "Telefon numaranızı giriniz.";
            } else if (!PHONE_REGEX.test(values.phone)) {
              errors.phone = "Geçersiz telefon numarası.";
            }

            if (!values.allowance) {
              errors.allowance = "Lütfen bu sözleşmeyi kabul ediniz.";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const birthday = moment(
              `${values.day}-${values.month}-${values.year}`,
              "DD-MM-YYYY"
            ).format("DD-MM-YYYY");
            values.birthday = birthday;
            values.email = values.email.trim().toLowerCase();
            const trimmedName = values.firstname.trim();
            const trimmedLastname = values.lastname.trim();

            const newData = {
              ...values,
              firstname: trimmedName,
              lastname: trimmedLastname,
            };
            try {
              const res = await tokenService();

              if (res.data === "OK") {
                const res = await register(values);
                if (res.data.status === 200) {
                  window.dataLayer.push({ ecommerce: null });
                  window.dataLayer.push({
                    event: "sign_up_form",
                    formType: "Üye Ol Form",
                    formStep: "2",
                  });

                  navigate(
                    "/auth/dogrulama-ekrani",
                    {
                      state: {
                        email: values.email,
                        phone: values.phone,
                        firstname: values.firstname.trim(),
                        lastname: values.lastname.trim(),
                      },
                    },
                    { replace: true }
                  );
                } else {
                  recaptchaRef.current.reset();
                  setError(res.data.message.toUpperCase());
                }
              } else {
                setError("Testi bir daha yapınız.");
                recaptchaRef.current.reset();
              }
            } catch (error) {
              setError("Bir daha deneyiniz.");
              console.log(error);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="register-form">
              <div
                className="form-area"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {" "}
                <div style={{ flex: "1" }}>
                  <label className="web" htmlFor="firstname">
                    Adın <span>*</span>
                  </label>
                  <img src={person} alt="person" className="input-img mobile" />{" "}
                  <Field
                    className="input-mobile"
                    type="text"
                    name="firstname"
                    placeholder="Adın"
                  />
                  <ErrorMessage
                    name="firstname"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div
                  style={{
                    flex: "1",
                    marginLeft: "10px",
                    position: "relative",
                  }}
                >
                  <label className="web" htmlFor="name">
                    Soyadın <span>*</span>
                  </label>
                  <img src={person} alt="person" className="input-img mobile" />{" "}
                  <Field
                    className="input-mobile"
                    type="text"
                    name="lastname"
                    placeholder="Soyadın"
                  />
                  <ErrorMessage
                    name="lastname"
                    component="div"
                    className="error-auth"
                  />
                </div>
              </div>
              {/* <div className="form-area">
                <div className=" order-container-form order-form">
                  <label htmlFor="email">
                    Doğum tarihiniz <span>*</span>
                  </label>
                  <DateTime
                    readonly
                    locale="tr"
                    inputProps={{ placeholder: "Gün/Ay/Yıl", readOnly: true }}
                    value={values.birthday}
                    onChange={(date) => {
                      const formattedDate = date.format("DD-MM-YYYY");
                      setFieldValue("birthday", formattedDate);
                    }}
                    dateFormat="DD-MMMM-YYYY"
                    timeFormat={false}
                  />

                  <ErrorMessage
                    name="birthday"
                    component="div"
                    className="error-auth"
                  />
                </div>
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "10px",
                  alignItems: "flex-end",
                }}
                className="form-area"
              >
                <div
                  style={{ flex: "1", width: "100px" }}
                  className="order-container-form order-form"
                >
                  <label style={{ fontSize: "16px" }} htmlFor="day">
                    Doğum gününüz <span>*</span>
                  </label>
                  <Field
                    type="number"
                    name="day"
                    placeholder="Gün"
                    className="date-input"
                  />
                </div>

                <div
                  style={{ flex: "1" }}
                  className="order-container-form order-form"
                >
                  <Field
                    type="number"
                    name="month"
                    placeholder="Ay"
                    className="date-input"
                  />
                  <ErrorMessage
                    name="month"
                    component="div"
                    className="error-auth"
                  />
                </div>

                <div
                  style={{ flex: "1" }}
                  className="order-container-form order-form"
                >
                  <Field
                    type="number"
                    name="year"
                    placeholder="Yıl"
                    className="date-input"
                  />
                  <ErrorMessage
                    name="year"
                    component="div"
                    className="error-auth"
                  />
                </div>
              </div> */}

              <div className="form-area">
                <label className="web" htmlFor="email">
                  E-posta <span>*</span>
                </label>
                <img
                  src={email}
                  alt="person"
                  className="input-img email mobile"
                />{" "}
                <Field
                  className="input input-mobile"
                  type="email"
                  value={values.email.trim().toLowerCase()}
                  name="email"
                  placeholder="E-posta adresinizi girin."
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div className="form-area">
                <label className="web" htmlFor="email">
                  Telefon <span>*</span>
                </label>
                <img
                  src={call}
                  alt="person"
                  className="input-img password mobile"
                />{" "}
                <InputMask
                  type="tel"
                  className="input-mobile"
                  name="phone"
                  placeholder="Telefon numarası gir"
                  mask="0599 999 9999"
                  maskChar="_"
                  regex={PHONE_REGEX}
                  onChange={(e) => {
                    const formattedValue = e.target.value.replace(/\s+/g, "");
                    // .replace("+", ""); // Remove plus sign
                    setFieldValue("phone", formattedValue);
                  }}
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div className="form-area">
                <label className="web" htmlFor="password">
                  Şifre <span>*</span>
                </label>
                <img src={password} alt="person" className="input-img mobile" />{" "}
                <Field
                  className="input input-mobile"
                  type="password"
                  name="password"
                  placeholder="Şifrenizi girin."
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-auth"
                />
              </div>

              <div className="form-area">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    position: "relative",
                    gap: "10px",
                    alignItems: "flex-end",
                  }}
                  className="form-area"
                >
                  <div
                    style={{ flex: "1", width: "100px" }}
                    className="order-container-form order-form"
                  >
                    <label className="birthdayLabel " htmlFor="day">
                      Doğum Tarihi <span>*</span>
                    </label>
                    <div
                      onClick={showPopupHandler}
                      className="information-image web"
                    >
                      <img
                        style={{
                          width: "20px",
                          marginRight: "5px",
                        }}
                        src={info}
                      />
                      <small>Bunu neden istiyoruz?</small>
                    </div>
                    {showPopup && (
                      <div className="why-popup">
                        <p>
                          Doğum tarihini sana özel <br />
                          <strong>kampanyalar ve fırsatlar </strong> <br />
                          oluşturmak için istiyoruz.
                        </p>
                        <div>
                          <img
                            onClick={hidePopupHandler}
                            className="cancel-why-popup"
                            src={cancel}
                          />
                        </div>
                      </div>
                    )}

                    <Field as="select" name="day" className="date-select">
                      <option value="">Gün</option>
                      {/* Generate day options dynamically */}
                      {Array.from({ length: 31 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div
                    style={{ flex: "1" }}
                    className="order-container-form order-form"
                  >
                    {/* <label htmlFor="month">
                    Ayı <span>*</span>
                  </label> */}
                    <Field as="select" name="month" className="date-select">
                      <option value="">Ay</option>
                      <option value="1">Ocak</option>
                      <option value="2">Şubat</option>
                      <option value="3">Mart</option>
                      <option value="4">Nisan</option>
                      <option value="5">Mayıs</option>
                      <option value="6">Haziran</option>
                      <option value="7">Temmuz</option>
                      <option value="8">Ağustos</option>
                      <option value="9">Eylül</option>
                      <option value="10">Ekim</option>
                      <option value="11">Kasım</option>
                      <option value="12">Aralık</option>
                    </Field>
                    {/* <ErrorMessage
                    name="month"
                    component="div"
                    className="error-auth"
                  /> */}
                  </div>
                  <div
                    style={{ flex: "1" }}
                    className="order-container-form order-form"
                  >
                    {/* <label htmlFor="year">
                    Yılı <span>*</span>
                  </label> */}
                    <Field as="select" name="year" className="date-select">
                      <option value="">Yıl</option>
                      {/* Generate year options dynamically */}
                      {Array.from({ length: 100 }, (_, index) => {
                        const year = 2023 - index;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </Field>
                    {/* <ErrorMessage
                    name="year"
                    component="div"
                    className="error-auth"
                  /> */}
                  </div>
                </div>
                <div>
                  <ErrorMessage
                    style={{ marginBottom: "10px", marginTop: "0" }}
                    name="day"
                    component="span"
                    className="error-auth"
                  />
                  {/* <ErrorMessage
                    style={{ marginBottom: "10px" }}
                    name="birthday"
                    component="span"
                    className="error-auth"
                  /> */}
                </div>
              </div>
              <div className="m-0 form-area d-flex terms">
                <Field type="checkbox" id="term" name="term" />
                <label
                  htmlFor="term"
                  style={{
                    width: "455px",
                  }}
                >
                  <p
                    className="m-0"
                    style={{
                      fontSize: "10px",
                      color: "#9393AA",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <span
                      onClick={() => {
                        setAydinlatma(true);
                      }}
                      className="term-links"
                    >
                      {" "}
                      Kişisel Verilere İlişkin Aydınlatma Metni
                    </span>{" "}
                    doğrultusunda{" "}
                    <span
                      onClick={() => {
                        setSatisSozlesmesi(true);
                      }}
                      className="term-links"
                    >
                      Gizlilik ve Güvenlik, Mesafeli Satış Sözleşmesi
                    </span>
                    'ni okudum, onaylıyorum.
                    {/* ve{" "} */}
                    {/* <span
                      onClick={() => {
                        setOnayMetni(true);
                      }}
                      className="term-links"
                    >
                      {" "}
                      Kişisel Verilere İlişkin Beyan ve Rıza Onay Metni
                    </span> */}
                  </p>
                </label>
              </div>
              <div className="terms  m-0 form-area d-flex">
                <Field type="checkbox" id="allowance" name="allowance" />
                <label
                  style={{
                    width: "455px",
                    fontSize: "10px",
                    color: "#9393AA",
                  }}
                  htmlFor="allowance"
                >
                  <p
                    className="m-0"
                    style={{
                      fontSize: "10px",
                      color: "#9393AA",
                      width: "100%",
                    }}
                  >
                    <span
                      onClick={() => {
                        setIletisimIzni(true);
                      }}
                      className="term-links"
                    >
                      İletişim İzni
                    </span>
                    ’ni okudum/ kabul ediyorum.
                  </p>
                </label>
              </div>
              <ErrorMessage
                name="allowance"
                component="div"
                className="error-auth"
              />
              <ErrorMessage
                name="term"
                component="div"
                className="error-auth"
              />
              {error && <div className="my-3 error-auth">{error}</div>}
              <div className="my-3">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={(token) => {
                    // This function will be called when the user completes the captcha
                    setCaptchaToken(token);

                    setError(""); // Clear any previous error messages
                  }}
                />
              </div>
              <div className="form-area web register-button-area">
                <Link
                  onClick={() => {
                    handleButtonClicks("", "Anasayfaya Geri Dön", "Üye ol");
                  }}
                  to="/"
                >
                  <span className="button button-ghost button-back">
                    Anasayfaya Geri Dön
                  </span>
                </Link>

                <button
                  type="submit"
                  className="button button-primary"
                  disabled={!values.term || !values.allowance}
                >
                  {isSubmitting ? "Üye Olunuyor..." : "Üye Ol"}
                </button>
              </div>
              <div
                className="mobile"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <button
                  type="submit"
                  className="button button-primary mobile-button-auth"
                  disabled={!values.term || !values.allowance}
                >
                  {isSubmitting ? "Üye Olunuyor..." : "Üye Ol"}
                </button>
              </div>
              <div className="mobile-auth">
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
                  <SocialAuth text={"Üye ol"} />
                </GoogleOAuthProvider>
              </div>
            </Form>
          )}
        </Formik>

        <Link
          onClick={() => {
            handleButtonClicks("", "Anasayfaya Geri Dön", "Üye ol");
          }}
          to="/"
        >
          <span
            style={{ color: "#676767" }}
            className=" mobile d-flex justify-content-center align-items-center "
          >
            Anasayfaya Geri Dön
          </span>
        </Link>
        <p className="mobile welcome-text mobile d-flex justify-content-center align-items-center mt-5 ">
          Hesabın var mı?{" "}
          <Link
            style={{
              marginLeft: "5px",
            }}
            to="/auth/giris-yap"
          >
            {" "}
            Giriş yap
          </Link>
        </p>
      </div>
    </>
  );
}

export default Register;
