import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import { useDispatch } from "react-redux";
import { registerSuccess } from "../../store/slices/accountSlice";
import { Helmet } from "react-helmet-async";
import baseService from "../../library/network/services/BaseService";
import {
  dijizinGetService,
  dijizinLoginService,
  dijizinService,
} from "../../library/network/requests/dijizn";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";
import { encodeDoc } from "../../utils/helpers/EncodeDoc";

const LoginSchema = Yup.object().shape({
  data: Yup.string()
    // .email("Bu alan boş bırakılamaz")
    .required("Bu alan boş bırakılamaz"),
  //   password: Yup.string().required("Şifre giriniz"),
});

function Verify() {
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const dijiService = async (values) =>
    baseService.post("webusers/updateDijiUid", values);
  const [docs, setDocs] = useState([]);
  const { state } = useLocation();
  const tokenService = async () =>
    baseService.post("verify-token", { token: captchaToken });
  const webuserService = async (values) =>
    baseService.post("webusers/confirm", values);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [clicked, setClicked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [captchaToken, setCaptchaToken] = useState("");

  const [otp, setOtp] = useState("");
  const getToken = async () => {
    const loginResponse = await dijizinLoginService();
    setToken(loginResponse.data.data.access_token);
  };
  const getAccessToken = async () => {
    const loginResponse = await dijizinLoginService();

    return loginResponse.data.data.access_token;
  };

  useEffect(() => {
    getToken();
  }, []);
  function formatDateToConsentTime(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (otp.length < 5) {
        setError("Doğrulama kodu 5 haneli olmalıdır");
      } else {
        const res = await tokenService();
        if (res.data === "OK") {
          const response = await webuserService({
            confirmationCode: otp,
            email: state?.email,
          });
          if (response.status === 200) {
            const access_token = await getAccessToken();

            const now = new Date();
            const options = {
              timeZone: "Europe/Istanbul",
              hour12: false,
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            };
            const formattedCurrentDate = now.toLocaleTimeString(
              "tr-TR",
              options
            );
            const Datenow = new Date();
            const optionsNow = {
              timeZone: "Europe/Istanbul",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            };

            const formattedDate = Datenow.toLocaleDateString(
              "tr-TR",
              optionsNow
            ).replace(/\./g, "-");

            const dataencode = await encodeDoc(
              `${state.firstname} ${state.lastname}`,
              formattedCurrentDate,
              state.email,
              state.phone,
              "auth/kayit-ol"
            );
            const dataModel = {
              source: "HS_WEB",
              first_name: state.firstname,
              last_name: state.lastname,
              email: state.email,
              country_code: "90",
              mobile_phone: state?.phone?.replace(/^0+/, ""),
              status: "ONAY",
              types: ["MESAJ", "EPOSTA"],
              recipient_type: "BIREYSEL",
              locale: "tr",
              kvkk: 1598,
              etk: 1600,
              send_method: "sms",
              description:
                "Bu izin onlinelostra.com üzerinden yeni üyelik sırasında ile alındı",
              origin: "http://www.dijiizin.com/",
            };
            const res = await dijizinService(
              access_token,
              "consents/single",
              dataModel
            );
            const userResponse = await dijiService({
              id: response.data.userData.id,
              dijiUid: res?.data?.customer?.id || "",
            });
            setMessage("Hesabınız başarı ile onaylanmıştır");
            setSuccess(true);

            dispatch(registerSuccess(state.email));
            storageHelper.setStoreWithEncryption("token", response.data.token);
            storageHelper.setStoreWithEncryption(
              "userId",
              response.data.userData.id
            );

            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              event: "sign_up",
              formType: "Üye Ol Form",
              formStep: "3",
              label: "basarili",
            });
            setTimeout(() => {
              setSuccess(false);
              navigate("/");
            }, 1500);
          }
        }
      }
    } catch (error) {
      setError("Bir sorun oluştu lütfen tekrar deneyiniz");
      console.log(error);
    }
  };
  const [countdown, setCountdown] = useState(120);
  const [isCounting, setIsCounting] = useState(true);
  useEffect(() => {
    let timeout;

    if (isCounting && countdown > 0) {
      timeout = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCounting(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isCounting, countdown]);

  const handleResendCode = async () => {
    const res = await baseService.post("webusers/sendCode", {
      email: state?.email,
    });
    // setIsCounting(true);
    // setCountdown(120);
  };

  return (
    <>
      <Helmet>
        <title>{`Şifre Doğrulama - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>
      <div
        style={{ width: "100%", padding: "0 100px" }}
        className="register-content forgot-password"
      >
        <h1>Doğrulama Kodu</h1>
        <small
          style={{
            color: " #9CA4AB",
          }}
        >
          Lütfen telefonunuza gelen doğrulama kodunu aşağıya girin.
        </small>
        <form className="my-5">
          {" "}
          <input
            className="input"
            type="text"
            value={otp}
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
          <div
            style={{
              gap: "30px",
              marginTop: "30px",
              flexWrap: "wrap",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link
              onClick={() => {
                handleButtonClicks("auth/login", "Geri Dön", "Hesap onayla");
              }}
              to="/auth/login"
            >
              <span
                style={{
                  lineHeight: "1.5",
                }}
                className="button-new button-ghost"
              >
                Geri Dön
              </span>
            </Link>{" "}
            <button
              style={{ fontSize: "14px" }}
              type="submit"
              onClick={handleSubmit}
              className="button-new button-primary"
            >
              Kod Onayla
            </button>
          </div>
          <div className="my-5">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              onChange={(token) => {
                // This function will be called when the user completes the captcha
                setCaptchaToken(token);

                setError(""); // Clear any previous error messages
              }}
            />
          </div>
          {error && (
            <div
              style={{
                marginTop: "20px",
                color: "red",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              {error}
            </div>
          )}
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "20px" }}
          >
            {countdown > 0 && (
              <span
                style={{ color: "#9CA4AB", fontSize: "12px" }}
                className="  d-flex justify-content-center align-items-center "
              >
                Tekrar Gönder ({Math.floor(countdown / 60)}.
                {countdown % 60 < 10 ? "0" : ""}
                {countdown % 60})
              </span>
            )}
            {countdown === 0 && (
              <button
                type="button"
                style={{ fontSize: "14px", backgroundColor: "#fff" }}
                onClick={handleResendCode}
                className="button-new button-ghost d-flex justify-content-center align-items-center "
                // disabled={isCounting}
              >
                Yeni Kod Gönder
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default Verify;
