import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import InputMask from "react-input-mask";
import {
  getUserById,
  updateWebuserDetails,
} from "../../../library/network/requests/webusers";

import cancel from "../../../utils/img/icons/remove.png";
import SuccessInfo from "../../app-layouts/SuccessInfo";
import { storageHelper } from "../../../utils/helpers/StorageHelper";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import info from "../../../utils/img/icons/info-pale.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Aydinlatma from "../../auth-layouts/components/Aydinlatma";
import Satis from "../Satis";
import AcikRiza from "../../auth-layouts/components/AcikRiza";
import Kvkk from "../Kvkk";
import baseService from "../../../library/network/services/BaseService";
import {
  dijizinGetService,
  dijizinLoginService,
  dijizinService,
} from "../../../library/network/requests/dijizn";
import { encodeDoc } from "../../../utils/helpers/EncodeDoc";

function MandatoryProfile() {
  const PHONE_REGEX = /^05(\d){2} ?\d{3} ?\d{4}$/;
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [aydınlatma, setAydinlatma] = useState(false);
  const [iletisimIzni, setIletisimIzni] = useState(false);
  const [onayMetni, setOnayMetni] = useState(false);
  const [satisSozlesmesi, setSatisSozlesmesi] = useState(false);
  const userId = storageHelper.getStoreWithDecryption("userId");
  const dijiService = async (values) =>
    baseService.post("webusers/updateDijiUid", values);
  const [docs, setDocs] = useState([]);
  const [token, setToken] = useState("");
  const [user, setUser] = useState([]);
  const getToken = async () => {
    const loginResponse = await dijizinLoginService();

    setToken(loginResponse.data.data.access_token);
  };
  const getAccessToken = async () => {
    const loginResponse = await dijizinLoginService();

    return loginResponse.data.data.access_token;
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    getUserById(userId).then((res) => {
      setUser(res.data);
    });
  }, []);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Adınızı giriniz"),
    lastname: Yup.string().required("Soyadınızı giriniz"),
    phone: Yup.string()
      .matches(PHONE_REGEX, "Geçersiz telefon numarası")
      .required("Telefon numaranızı giriniz"),
    newpassword: Yup.string().required("Yeni şifrenizi giriniz"),

    // Date validation for day, month, year
    day: Yup.number().integer().required("Gün alanı boş bırakılamaz"),
    term: Yup.boolean().oneOf([true], "Koşulları kabul etmelisiniz"),
    allowance: Yup.boolean().oneOf([true], "İletişim izni vermelisiniz"),
    month: Yup.number().integer().required("Ay alanı boş bırakılamaz"),
    year: Yup.number()
      .integer()
      .required("Yıl alanı boş bırakılamaz")
      .test("isValidDate", "Geçersiz tarih", function (value) {
        const { day, month, year } = this.parent;
        if (day && month && year) {
          return new Date(year, month - 1, day).getDate() === parseInt(day);
        }
        return true; // Allow empty values to be handled by the 'required' validation
      }),
  });

  const hidePopupHandler = () => {
    setShowPopup(false);
  };
  const showPopupHandler = () => {
    setShowPopup(true);
  };
  return (
    <>
      <Helmet>
        <title>{`Hesabım - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>
      {onayMetni && <AcikRiza setOnayMetni={setOnayMetni} />}
      {aydınlatma && (
        <div className="modal-overlay">
          <div className="terms-container">
            {" "}
            <Kvkk />
            <button
              onClick={() => setAydinlatma(false)}
              className="btn cancel-button"
            >
              Kapat
            </button>
          </div>
        </div>
      )}
      {iletisimIzni && <Aydinlatma setAydinlatma={setIletisimIzni} />}
      {satisSozlesmesi && (
        <div className="modal-overlay">
          <div className="terms-container">
            {" "}
            <Satis />
            <button
              onClick={() => setSatisSozlesmesi(false)}
              className="btn cancel-button"
            >
              Kapat
            </button>
          </div>
        </div>
      )}
      <div className="register-content">
        <h3>Profilim</h3>

        <Formik
          validationSchema={validationSchema}
          enableReinitialize={true}
          initialValues={{
            firstname: user.firstname || "",
            lastname: user.lastname || "",
            phone: user.phone || "",
            email: user.email || "",

            newpassword: "",
            birthday: "",
            day: "",
            month: "",
            year: "",
            term: false,
            allowance: false,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const birthday = moment(
              `${values.day}-${values.month}-${values.year}`,
              "DD-MM-YYYY"
            ).format("DD-MM-YYYY");
            values.birthday = birthday;
            values.email = values.email.trim().toLowerCase();
            const model = {
              lastname: values?.lastname ? values.lastname : user.lastname,
              firstname: values?.firstname ? values.firstname : user.firstname,
              birthday: values?.birthday ? values.birthday : user.birthday,
              phone: values?.phone ? values.phone : user.phone,
              password: values?.newpassword
                ? values.newpassword
                : user.password,
              email: values?.email ? values.email : user.email,
            };
            const response = await updateWebuserDetails(user._id, model);

            if (response.status === 200) {
              const access_token = await getAccessToken();

              const now = new Date();
              const options = {
                timeZone: "Europe/Istanbul",
                hour12: false,
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              };
              const formattedCurrentDate = now.toLocaleTimeString(
                "tr-TR",
                options
              );
              const Datenow = new Date();
              const optionsNow = {
                timeZone: "Europe/Istanbul",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              };

              const formattedDate = Datenow.toLocaleDateString(
                "tr-TR",
                optionsNow
              ).replace(/\./g, "-");

              const dataencode = await encodeDoc(
                `${values.firstname} ${values.lastname}`,
                formattedCurrentDate,
                values.email,
                values.phone,
                "auth/kayit-ol"
              );
              const dataModel = {
                source: "HS_WEB",
                first_name: values.firstname,
                last_name: values.lastname,
                email: values.email,
                country_code: "90",
                mobile_phone: values?.phone?.replace(/^0+/, ""),
                status: "ONAY",
                types: ["MESAJ", "EPOSTA"],
                recipient_type: "BIREYSEL",
                locale: "tr",
                kvkk: 1598,
                etk: 1600,
                send_method: "sms",
                description:
                  "Bu izin onlinelostra.com üzerinden yeni üyelik sırasında ile alındı",
                origin: "http://www.dijiizin.com/",
              };
              const res = await dijizinService(
                access_token,
                "consents/single",
                dataModel
              );
              const userResponse = await dijiService({
                id: response.data._id,
                dijiUid: res?.data?.customer?.id || "",
              });
              if (userResponse.status === 200) {
                setMessage("İşleminiz başarılı!");
                setSuccess(true);
                navigate("/");
              }
            }
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="register-form">
              <div
                className="form-area"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {" "}
                <div style={{ flex: "1" }}>
                  <label htmlFor="firstname">Adın</label>
                  <Field type="text" name="firstname" placeholder="Adın" />
                  <ErrorMessage
                    name="firstname"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div style={{ flex: "1", marginLeft: "10px" }}>
                  <label htmlFor="name">Soyadın</label>
                  <Field type="text" name="lastname" placeholder="Soyadın" />
                  <ErrorMessage
                    name="lastname"
                    component="div"
                    className="error-auth"
                  />
                </div>
              </div>
              <div className="form-area">
                <label htmlFor="email">E-posta</label>
                <Field
                  className="input"
                  type="email"
                  readOnly
                  name="email"
                  placeholder="E-posta adresinizi girin."
                />
              </div>
              <div className="form-area">
                <label htmlFor="phone">
                  Telefon Numarası <span>*</span>
                </label>

                <InputMask
                  type="tel"
                  name="phone"
                  placeholder="Telefon numaranızı girin."
                  mask="0599 999 9999"
                  value={values.phone}
                  maskChar="_"
                  regex={PHONE_REGEX}
                  onChange={(e) => {
                    const formattedValue = e.target.value.replace(/\s+/g, "");
                    setFieldValue("phone", formattedValue);
                  }}
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div className="form-area">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    position: "relative",
                    gap: "10px",
                    alignItems: "flex-end",
                  }}
                  className="form-area"
                >
                  <div
                    style={{ flex: "1", width: "100px" }}
                    className="order-container-form order-form"
                  >
                    <label className="birthdayLabel " htmlFor="day">
                      Doğum Tarihi <span>*</span>
                    </label>
                    <div
                      onClick={showPopupHandler}
                      className="information-image web"
                    >
                      <img
                        style={{
                          width: "20px",
                          marginRight: "5px",
                        }}
                        src={info}
                      />
                      <small>Bunu neden istiyoruz?</small>
                    </div>
                    {showPopup && (
                      <div className="why-popup">
                        <p>
                          Doğum tarihini sana özel <br />
                          <strong>kampanyalar ve fırsatlar </strong> <br />
                          oluşturmak için istiyoruz.
                        </p>
                        <div>
                          <img
                            onClick={hidePopupHandler}
                            className="cancel-why-popup"
                            src={cancel}
                          />
                        </div>
                      </div>
                    )}

                    <Field as="select" name="day" className="date-select">
                      <option value="">Gün</option>
                      {Array.from({ length: 31 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div
                    style={{ flex: "1" }}
                    className="order-container-form order-form"
                  >
                    <Field as="select" name="month" className="date-select">
                      <option value="">Ay</option>
                      <option value="1">Ocak</option>
                      <option value="2">Şubat</option>
                      <option value="3">Mart</option>
                      <option value="4">Nisan</option>
                      <option value="5">Mayıs</option>
                      <option value="6">Haziran</option>
                      <option value="7">Temmuz</option>
                      <option value="8">Ağustos</option>
                      <option value="9">Eylül</option>
                      <option value="10">Ekim</option>
                      <option value="11">Kasım</option>
                      <option value="12">Aralık</option>
                    </Field>
                    {/* <ErrorMessage
                    name="month"
                    component="div"
                    className="error-auth"
                  /> */}
                  </div>
                  <div
                    style={{ flex: "1" }}
                    className="order-container-form order-form"
                  >
                    <Field as="select" name="year" className="date-select">
                      <option value="">Yıl</option>
                      {Array.from({ length: 100 }, (_, index) => {
                        const year = 2023 - index;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </div>
                <div>
                  <ErrorMessage
                    style={{ marginBottom: "10px", marginTop: "0" }}
                    name="day"
                    component="span"
                    className="error-auth"
                  />
                </div>
              </div>{" "}
              <div
                className="form-area"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: "1" }} className="form-area">
                  <label htmlFor="passwordCheck">
                    Şifre <span>*</span>{" "}
                  </label>
                  <Field
                    type="password"
                    className="input"
                    name="newpassword"
                    placeholder="Yeni Şifrenizi  girin."
                  />
                  <ErrorMessage
                    name="newpassword"
                    component="div"
                    className="error-auth"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "30px",
                }}
              >
                {" "}
                <div className="m-0 form-area d-flex terms">
                  <Field type="checkbox" id="term" name="term" />
                  <label
                    htmlFor="term"
                    style={{
                      width: "455px",
                    }}
                  >
                    <p
                      className="m-0"
                      style={{
                        fontSize: "10px",
                        color: "#9393AA",
                        width: "100%",
                      }}
                    >
                      {" "}
                      <span
                        onClick={() => {
                          setAydinlatma(true);
                        }}
                        className="term-links"
                      >
                        {" "}
                        Kişisel Verilere İlişkin Aydınlatma Metni
                      </span>{" "}
                      doğrultusunda{" "}
                      <span
                        onClick={() => {
                          setSatisSozlesmesi(true);
                        }}
                        className="term-links"
                      >
                        Gizlilik ve Güvenlik, Mesafeli Satış Sözleşmesi
                      </span>{" "}
                      ve{" "}
                      <span
                        onClick={() => {
                          setOnayMetni(true);
                        }}
                        className="term-links"
                      >
                        {" "}
                        Kişisel Verilere İlişkin Beyan ve Rıza Onay Metni
                      </span>
                      'ni okudum, onaylıyorum.
                    </p>
                  </label>
                </div>
                <div className="terms  m-0 form-area d-flex">
                  <Field type="checkbox" id="allowance" name="allowance" />
                  <label
                    style={{
                      width: "455px",
                      fontSize: "10px",
                      color: "#9393AA",
                    }}
                    htmlFor="allowance"
                  >
                    <p
                      className="m-0"
                      style={{
                        fontSize: "10px",
                        color: "#9393AA",
                        width: "100%",
                      }}
                    >
                      <span
                        onClick={() => {
                          setIletisimIzni(true);
                        }}
                        className="term-links"
                      >
                        İletişim İzni
                      </span>
                      ’ni okudum/ kabul ediyorum.
                    </p>
                  </label>
                </div>
                <ErrorMessage
                  name="term"
                  component="div"
                  className="error-auth"
                />
                <ErrorMessage
                  name="allowance"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div className="form-area d-flex justify-content-between align-items-center">
                <button
                  type="submit"
                  className="button button-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Kaydetiliyor..." : "Kaydet"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {success && <SuccessInfo message={message} />}
        {!success && (
          <SuccessInfo
            message={"Devam etmek için hesap bilgilerinizi tamamlayınız."}
          />
        )}
      </div>
    </>
  );
}

export default MandatoryProfile;
