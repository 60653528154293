import axios from "axios";

export const dijizinService = async (token, url, data) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(
    `https://app.dijiizin.com/api/${url}`,
    data,
    {
      headers,
    }
  );
  return response;
};
export const dijizinLoginService = async () => {
  const response = await axios.post(`https://app.dijiizin.com/api/auth/login`, {
    client_id: process.env.REACT_APP_DIJIIZIN_USERNAME,
    client_secret: process.env.REACT_APP_DIJIIZIN_PASSWORD,
  });
  return response;
};
export const dijizinGetService = async (token, url) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(`https://app.dijiizin.com/api/${url}`, {
    headers,
  });
  return response;
};
